import React, { RefObject } from "react";
import styled, { css } from "styled-components";
import { HTMLMotionProps, motion } from "framer-motion";
import { ThemeColorsType } from "@providers/ThemeProvider/theme";

type TagsType =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "p"
  | "span"
  | "a"
  | "button"
  | "div";

type SizeType =
  | "4.8rem"
  | "4rem"
  | "3.2rem"
  | "2.4rem"
  | "2rem"
  | "1.8rem"
  | "1.6rem"
  | "1.4rem";

type WeightType = 400 | 500 | 700;

interface IProps extends HTMLMotionProps<"div"> {
  children?: React.ReactNode;
  className?: string;
  tag?: TagsType;
  fontSize?: SizeType;
  weight?: WeightType;
  color?: ThemeColorsType;
  lineHeight?: number;
}

const StyledText = styled(motion.div)<{
  $fontSize: IProps["fontSize"];
  $weight: IProps["weight"];
  $color: IProps["color"];
  $lineHeight: IProps["lineHeight"];
}>`
  ${(props) => {
    const mobileFontSizes = {
      "4.8rem": "2.8rem",
      "4rem": "2.8rem",
      "2.4rem": "1.8rem",
      "2rem": "1.8rem",
      "1.8rem": "1.8rem",
      "1.6rem": "1.6rem",
    };

    const mobileFontSize = mobileFontSizes[props.$fontSize] ?? props.$fontSize;

    return css`
      font-size: ${props.$fontSize};
      font-weight: ${props.$weight};
      color: ${props.theme.colors[props.$color]};
      line-height: ${props.$lineHeight}%;

      @media screen and (max-width: ${props.theme.sizes.tablet}) {
        font-size: ${mobileFontSize};
      }
    `;
  }}
`;

export const Typography = React.forwardRef(
  (
    {
      tag = "span",
      weight = 400,
      fontSize = "1.6rem",
      children,
      className,
      color = "basic_black",
      lineHeight = 120,
      ...props
    }: IProps,
    ref
  ) => (
    <StyledText
      className={className}
      as={motion[tag]}
      $weight={weight}
      $fontSize={fontSize}
      $color={color}
      $lineHeight={lineHeight}
      ref={ref as RefObject<HTMLAnchorElement>}
      {...props}
    >
      {children}
    </StyledText>
  )
);
