import styled, { css } from "styled-components";
import { ThemeSizesType } from "@providers/ThemeProvider/theme";

const Container = styled.div<{ $size?: ThemeSizesType }>`
  ${({ theme, $size }) => css`
    max-width: ${$size ? theme.sizes[$size] : theme.sizes["1120"]};
    margin: 0 auto;

    @media screen and (max-width: ${(props) => props.theme.sizes["1120"]}) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  `}
`;

const CodeTag = styled.code`
  background: #fafafa;
  border-radius: 5px;
  margin: 0 0.75rem;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
`;

export { Container, CodeTag };
